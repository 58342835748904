const CookieApi = {
  getCookieValue(name: string) {
    const pair = document.cookie.match(new RegExp(name + '=([^;]+)'));
    if (!!pair) {
      return pair[1];
    }
    return null;
  },

  setCookie: (name: string, value: string, expires?: string) => {
    document.cookie = name + '=' + value + '; domain=.telia.ee' + '; expires=' + expires + '; path=/;';
  }
};

export default CookieApi;
