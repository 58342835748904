import { action, makeObservable, observable, reaction } from 'mobx';
import API from '../util/ApiUtil';
import { parseParams } from '../util/ParamsUtil';
import { ILocaleStore, IRootStore } from '../typings/store';
import { AxiosError, AxiosResponse } from 'axios';

interface ITranslationMapping {
  [key: string]: ITranslationGroup;
}

interface ITranslationGroup {
  [key: string]: string;
}

interface ITranslationParameterMapping {
  [value: string]: any;
}

class TranslateStore {
  @observable translations: ITranslationMapping = {};
  @observable public translationsRaw: ITranslationMapping = {};

  public globalGroupName = 'global';
  private appGroupName = 'rowfe';
  private localeStore: ILocaleStore;

  // recursively convert translation keys to lowercase for case-insensitivity
  static keysToLowerCase(translations: ITranslationMapping | ITranslationGroup): ITranslationMapping {
    const lowerCasedTranslations = {};

    if (!translations) {
      return lowerCasedTranslations;
    }

    for (const key in translations) {
      /* istanbul ignore else */
      if (translations.hasOwnProperty(key)) {
        lowerCasedTranslations[key.toLowerCase()] =
          {}.toString.call(translations[key]) === '[object Object]'
            ? this.keysToLowerCase(translations[key] as ITranslationGroup)
            : translations[key];
      }
    }

    return lowerCasedTranslations;
  }

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.localeStore = rootStore.localeStore;
  }

  async init() {
    reaction(
      () => this.localeStore.currentLocale,
      lang => this.updateTranslations(lang)
    );
    await this.updateTranslations(this.localeStore.currentLocale);
  }

  private async updateTranslations(lang: string) {
    return API.get(`/translations/${lang}`)
      .then((response: AxiosResponse) => this.setTranslations(response.data.translations))
      .catch((error: AxiosError) => console.error(error));
  }

  @action
  private setTranslations = (translations: ITranslationMapping) => {
    this.translations = TranslateStore.keysToLowerCase(translations);
    this.translationsRaw = translations;
  };

  public translateHtml = (key: string, paramObject?: ITranslationParameterMapping) => ({ __html: this.translate(key, paramObject) });

  public translate = (key: string, paramObject?: ITranslationParameterMapping) =>
    this.getTranslationForGroup(this.appGroupName, key, paramObject);

  public translateGlobal = (key: string, paramObject?: ITranslationParameterMapping) =>
    this.getTranslationForGroup(this.globalGroupName, key, paramObject);

  public getTranslationsByKeyPrefix = (keyPrefix: string, sort: boolean, paramObject?: ITranslationParameterMapping) => {
    keyPrefix = keyPrefix.toLowerCase();
    const groupTranslations = this.translations[this.appGroupName.toLowerCase()];
    if (!groupTranslations) {
      return [];
    }
    const keys = Object.keys(groupTranslations).filter(key => key.startsWith(keyPrefix));
    const sortedKeys = sort ? this.sortKeys(keys) : keys;
    return sortedKeys.map(key => ({ __html: this.getTranslationWithParsedParams(groupTranslations[key], key, paramObject) }));
  };

  private sortKeys(keys: string[]): string[] {
    return keys.sort((key1, key2) => {
      const key1Splitted = key1.split('.');
      const key2Splitted = key2.split('.');
      const key1Order = key1Splitted[key1Splitted.length - 1];
      const key2Order = key2Splitted[key2Splitted.length - 1];
      const numberMatch = /^\d+$/;
      if (!numberMatch.test(key1Order) || !numberMatch.test(key2Order)) {
        return 0;
      }
      return Number(key1Order) > Number(key2Order) ? 1 : -1;
    });
  }

  private getTranslationForGroup = (group: string, key: string, paramObject?: ITranslationParameterMapping) => {
    // for case-insensitivity
    key = key.toLowerCase();
    group = group.toLowerCase();

    const groupTranslations = this.translations[group.toLowerCase()];
    let textValue;

    if (groupTranslations) {
      textValue = groupTranslations[key];
    }
    return this.getTranslationWithParsedParams(textValue, key, paramObject);
  };

  private getTranslationWithParsedParams(textValue: string, key: string, paramObject?: ITranslationParameterMapping) {
    if (textValue && paramObject) {
      return parseParams(textValue, paramObject);
    }

    return textValue || key;
  }
}

export default TranslateStore;
