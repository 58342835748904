import TranslateStore from '../store/TranslateStore';
import { LabelProvider } from 'telia-front-react';
import { inject, observer } from 'mobx-react';
import React from 'react';

export interface IRoamingLabelProviderProps {
  children?: React.ReactChild | React.ReactChild[];
  translateStore?: TranslateStore;
}

const RoamingLabelProvider = (props: IRoamingLabelProviderProps): JSX.Element => {
  const { children, translateStore } = props;

  return <LabelProvider labels={translateStore.translationsRaw[translateStore.globalGroupName] || {}}>{children}</LabelProvider>;
};

export default inject('translateStore')(observer(RoamingLabelProvider));
