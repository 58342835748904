import axios from 'axios';
import { stringify } from 'qs';
import config from '../config';

export const apiBaseUrl = config.apiUrl;

const API = axios.create({
  baseURL: apiBaseUrl,
  timeout: 100000,
  paramsSerializer: params => stringify(params, { arrayFormat: 'repeat' })
});

export default API;
