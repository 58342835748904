import { action, makeObservable, observable } from 'mobx';

export enum Brand {
  TELIA = 'telia',
  DIIL = 'diil'
}
export default class BrandfulStore {
  public static DEFAULT_BRAND = Brand.TELIA;
  private static ALLOWED_SVG_NAMES = ['arrow-right', 'check', 'check-round', 'alert', 'arrow-left'];

  @observable public currentBrand: Brand;
  icons;

  constructor() {
    makeObservable(this);
    this.setNewBrand(BrandfulStore.DEFAULT_BRAND);
    this.loadIcons();
  }

  @action
  public setNewBrand(brand: string) {
    this.currentBrand = brand.toLowerCase() === Brand.DIIL ? Brand.DIIL : Brand.TELIA;
  }

  public getCssUrl(): string {
    return `/roamingredirect-frontend/static/css/roaming_${this.currentBrand}.css`;
  }

  public getIconPath(name) {
    if (this.icons[name]) {
      if (this.icons[name].symbol) {
        return this.icons[name].symbol;
      } else {
        return this.icons[name];
      }
    }
    return name;
  }

  loadIcons(): void {
    const req = this.getIconsData();
    this.icons = req
      .keys()
      .filter(key => this.isIconAllowed(key))
      .reduce((glyphs: any, key: string) => {
        const iconSpriteKey = '#' + this.getIconName(key);
        return {
          ...glyphs,
          [iconSpriteKey]: req(key)
        };
      }, {});
  }

  getIconsData() {
    // @ts-ignore
    return require.context('@telia-front/icon/svg/', false, /^\.\/.*\.svg$/);
  }

  isIconAllowed(iconPath: string) {
    return BrandfulStore.ALLOWED_SVG_NAMES.some(svg => svg === this.getIconName(iconPath));
  }

  private getIconName(iconPath: string) {
    const match = iconPath.match(new RegExp(/[^/]+(?=\.svg$)/));
    return match && match[0];
  }
}
