import { action, makeObservable, observable } from 'mobx';
import pathToRegexp from 'path-to-regexp';
import { ILocaleStore, IRootStore, Locale } from '../typings/store';
import CookieApi from '../util/CookieUtil';
import moment from 'moment';
import NavigationStore from './NavigationStore';

class LocaleStore implements ILocaleStore {
  @observable public currentLocale: Locale;

  public locales: Locale[] = ['et', 'ru', 'en'];
  public pathLocalePrefixPattern = `/:lang(${this.locales.join('|')})?`;
  private rootStore: IRootStore;
  private guestLanguageCookieName = 'LANGUAGE_EXT';
  private defaultLocale: Locale = 'et';
  private pathLocaleRegexp = pathToRegexp(this.pathLocalePrefixPattern + '/(.*)');
  private languageIdToLocaleMap = {
    et_EE: 'et',
    ru_RU: 'ru',
    en_GB: 'en'
  };
  private navigationStore: NavigationStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.navigationStore = rootStore.navigationStore;
    this.init();
  }

  private init() {
    const langFromPath = this.navigationStore.langFromPath();
    if (langFromPath) {
      this.setLocale(langFromPath as Locale);
    }
    const storedLang = this.getLanguageFromCookie();
    if (storedLang && storedLang !== this.currentLocale) {
      this.setLocale(storedLang as Locale);
    }
    if (!this.currentLocale) {
      this.setLocale(this.defaultLocale);
    }
    moment.locale(this.currentLocale);
  }

  @action
  public setLocale(locale: Locale | 'et'): void {
    this.updateLanguageCookie(locale);
    this.navigationStore.updateLangInPath(locale, this.defaultLocale);
    this.currentLocale = locale;
    moment.locale(locale);
    document.querySelector('html').setAttribute('lang', locale);
  }

  public getPathWithCurrentLocale(path: string, includeDefaultLocale?: boolean): string {
    return this.getPathWithLocale(path, this.currentLocale, includeDefaultLocale);
  }

  public getCurrentPathWithLocale(locale?: Locale): string {
    return this.getPathWithLocale(this.rootStore.router.location.pathname, locale ? locale : this.currentLocale);
  }

  private getPathWithLocale(path: string, locale: Locale, includeDefaultLocale?: boolean | false): string {
    if (!path.startsWith('/')) {
      path = '/' + path;
    }
    const prefix = locale === this.defaultLocale && !includeDefaultLocale ? '/' : `/${locale}/`;
    return prefix + this.pathLocaleRegexp.exec(path)[2];
  }

  private updateLanguageCookie(language: Locale) {
    /* istanbul ignore else */
    if (CookieApi.getCookieValue(this.guestLanguageCookieName) !== language) {
      const language_id = this.getLanguageIdFromLocale(language);
      const expirationDate = new Date();
      expirationDate.setFullYear(new Date().getFullYear() + 1);
      CookieApi.setCookie(this.guestLanguageCookieName, language_id, expirationDate.toUTCString());
    }
  }

  private getLanguageIdFromLocale(locale: Locale): string {
    return Object.keys(this.languageIdToLocaleMap).find(key => this.languageIdToLocaleMap[key] === locale);
  }

  private getLanguageFromCookie() {
    return this.resolveLocale(CookieApi.getCookieValue(this.guestLanguageCookieName) as Locale);
  }

  private resolveLocale = (languageId: string) => this.languageIdToLocaleMap[languageId] as Locale;
}

export default LocaleStore;
