import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Redirect, Route, RouteProps } from 'react-router';

import FeatureStore from '../component/featureFlag/FeatureStore';
import NavigationStore from '../store/NavigationStore';

export interface IProtectedRouteProps extends RouteProps {
  featureStore?: FeatureStore;
  feature?: string;
  children?: React.ReactChild | React.ReactChild[] | (() => React.ReactChild | React.ReactChild[]);
}

@inject('featureStore')
@observer
export default class ProtectedRoute extends React.Component<IProtectedRouteProps> {
  render(): JSX.Element {
    const { featureStore } = this.props;

    if (!featureStore.initialized) {
      return null;
    }

    const children = this.isRouteEnabled() ? this.renderChildren() : <Redirect to={NavigationStore.SELF} />;
    return <Route {...this.props}>{children}</Route>;
  }

  private isRouteEnabled(): boolean {
    const { feature, featureStore } = this.props;
    if (!feature) {
      return true;
    }

    return featureStore.isEnabled(feature);
  }

  private renderChildren() {
    const { children } = this.props;
    return children instanceof Function ? children() : children;
  }
}
