import { action, makeObservable, observable } from 'mobx';

import { IRootStore } from '../../typings/store';
import FeatureFlag from '../../model/featureFlag/FeatureFlag';
import RoamingApi from '../../service/RoamingRedirectApi';

class FeatureStore {
  public static ENABLE_ROAMING = 'ENABLE_ROAMING';

  rootStore: IRootStore;

  @observable featureFlags: FeatureFlag[] = [];
  @observable initialized = false;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  async init() {
    await this.initFeatureFlags();
    this.setInitialized();
  }

  getFeatureFlag(feature: string): FeatureFlag {
    return this.featureFlags.find(feat => feat.feature === feature);
  }

  isEnabled(feature: string): boolean {
    const featureFlag = this.getFeatureFlag(feature);
    return !!featureFlag && featureFlag.enabled;
  }

  @action
  private setInitialized = () => {
    this.initialized = true;
  };

  @action
  private setFeatureFlags = (featureFlags: FeatureFlag[]) => {
    this.featureFlags = featureFlags;
  };

  private async initFeatureFlags() {
    try {
      const featureFlags = await RoamingApi.fetchFeatureFlags();
      this.setFeatureFlags(featureFlags);
    } catch (error) {
      error.message = 'Failed to fetch feature flags';
      this.rootStore.errorStore.setGeneralTechnicalError(error);
    }
  }
}

export default FeatureStore;
