import * as React from 'react';
import { inject, observer } from 'mobx-react';

import AppError from '../model/AppError';
import ErrorStore from '../store/ErrorStore';

type RenderCallback = (error?: AppError) => React.ReactNode;

interface IErrorProviderProps {
  level?: string;
  children: RenderCallback;
  errorStore?: ErrorStore;
}

@inject('errorStore')
@observer
export class ErrorProvider extends React.Component<IErrorProviderProps> {
  render() {
    const { level, errorStore, children } = this.props;
    const errors = errorStore.errors;
    const error: AppError = level ? errors.get(level) : errors.values().next().value;
    return error ? children(error) : null;
  }
}

export default ErrorProvider;
