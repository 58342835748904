import { action, makeObservable, observable } from 'mobx';

export const FULL_PAGE = 'fullPage';
export const PROGRESS_ITEM = 'progressItem';

export class SpinnerStore {
  @observable spinnerCache: Map<string, boolean> = new Map();

  constructor() {
    makeObservable(this);
  }

  isVisible(name: string) {
    return this.spinnerCache.get(name);
  }

  @action
  show(spinnerName) {
    this.spinnerCache.set(spinnerName, true);
  }

  @action
  hide(spinnerName) {
    this.spinnerCache.set(spinnerName, false);
  }
}

export default SpinnerStore;
