import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { Router, Switch } from 'react-router';
import createRootStore from './store/RootStore';
import NavigationStore from './store/NavigationStore';
import { TECHNICAL_ERROR } from './store/ErrorStore';
import { IAppErrorValue } from './model/AppError';
import ErrorProvider from './component/ErrorProvider';
import { routes } from './pages';
import { IRoute } from './typings';
import ProtectedRoute from './pages/ProtectedRoute';
import { Helmet } from 'react-helmet';
import { ThemeProvider, Modal, ModalFooter, Icon, Button, Grid, GridColumn, Overlay, Main, MainContent } from 'telia-front-react';
import RoamingLabelProvider from './wrapper/RoamingLabelProvider';

const rootStore = createRootStore();
rootStore.init();

const overlay = <Overlay spinner={true} />;
@observer
class App extends React.Component {
  private static getRetryColumn(): JSX.Element {
    const { translate } = rootStore.translateStore;
    return (
      <GridColumn width={['xs-12', 'sm']} order={['last-sm']}>
        <div className="text-center">
          <Button iconRight="#arrow-right" onClick={App.reload} text={translate('error.backend.general.retry')} />
        </div>
      </GridColumn>
    );
  }

  static reload() {
    window.onbeforeunload = null;
    rootStore.errorStore.clearError(TECHNICAL_ERROR);
    window.location.reload();
  }

  static navigateToHome() {
    window.onbeforeunload = null;
    rootStore.errorStore.clearError(TECHNICAL_ERROR);
    rootStore.navigationStore.navigateTo(NavigationStore.SELF);
  }

  static renderErrorModal(error: IAppErrorValue) {
    const { translate, translateHtml } = rootStore.translateStore;
    const retryColumn = error.isRetryNotAllowed ? null : App.getRetryColumn();

    return (
      <Modal size="8-col" isOpen={true} onClose={error.isRetryNotAllowed ? App.navigateToHome : App.reload}>
        <div className="text-center">
          <h4>
            <span className="text-error">
              <Icon modifiers="#alert" />
            </span>
            {'\u00a0\u00a0'}
            <span dangerouslySetInnerHTML={translateHtml(error.message as string)} />
          </h4>
        </div>
        <ModalFooter>
          <Grid align={['between-sm', 'middle-xs']}>
            {retryColumn}
            <GridColumn width={['xs-12', 'sm']}>
              <div className="text-center">
                <Button
                  iconLeft="#arrow-left"
                  onClick={App.navigateToHome}
                  text={translate('error.backend.general.goto.home')}
                  link={true}
                />
              </div>
            </GridColumn>
          </Grid>
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const brandfulStore = rootStore.brandfulStore;
    return (
      <ThemeProvider brand={brandfulStore.currentBrand} getIconPath={name => brandfulStore.getIconPath(name)}>
        <Provider {...rootStore}>
          <Router history={rootStore.router.history}>
            <Helmet>
              <link rel="stylesheet" href={brandfulStore.getCssUrl()} />
            </Helmet>
            <Main>
              <RoamingLabelProvider>
                <MainContent>
                  <ErrorProvider>{App.renderErrorModal}</ErrorProvider>
                  <React.Suspense fallback={overlay}>
                    <Switch>
                      {routes.map((route: IRoute) => (
                        <ProtectedRoute
                          feature={route.feature}
                          key={route.path}
                          exact={true}
                          path={rootStore.localeStore.pathLocalePrefixPattern + route.path}
                        >
                          <route.component />
                        </ProtectedRoute>
                      ))}
                    </Switch>
                  </React.Suspense>
                </MainContent>
              </RoamingLabelProvider>
            </Main>
          </Router>
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
