import { IRoute } from '../typings';
import FeatureStore from '../component/featureFlag/FeatureStore';
import { ComponentType, lazy } from 'react';

export const routes: IRoute[] = [
  {
    path: '/',
    component: lazy<ComponentType<any>>(() => import(/* webpackChunkName: 'RoamingRedirect' */ './RoamingRedirect')),
    feature: FeatureStore.ENABLE_ROAMING
  }
];
