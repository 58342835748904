import { action, makeObservable, observable } from 'mobx';

import { IRootStore } from '../typings/store';
import AppError, { IAppErrorValue } from '../model/AppError';

export const VALIDATION_ERROR = 'validationError';
export const TECHNICAL_ERROR = 'technicalError';
export const BUSINESS_ERROR = 'businessError';

type Errors = Map<string, AppError>;

export default class ErrorStore {
  rootStore: IRootStore;

  @observable errors: Errors = new Map();

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @action
  public setError(level: string, value: AppError): Errors {
    if (process.env.NODE_ENV === 'development') {
      this.logError(value);
    }

    return this.errors.set(level, value);
  }

  @action
  public clearError(level: string): boolean {
    return this.errors.delete(level);
  }

  @action
  public reset() {
    this.errors.clear();
  }

  public getError(level: string): AppError {
    return this.errors.get(level);
  }

  public setGeneralTechnicalError(originalError: IAppErrorValue['originalError']): Errors {
    return this.setError(TECHNICAL_ERROR, {
      message: 'error.backend.general',
      type: 'error',
      originalError
    });
  }

  private logError(value: AppError): void {
    if (isAppErrorValue(value)) {
      console.error(value.originalError || value.message);
    } else {
      for (const key in value) {
        /* istanbul ignore else */
        if (value.hasOwnProperty(key)) {
          this.logError(value[key]);
        }
      }
    }
  }
}

export function isAppErrorValue(value: AppError): value is IAppErrorValue {
  return (value as IAppErrorValue).message !== undefined && (value as IAppErrorValue).type !== undefined;
}
