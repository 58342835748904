import { computed, makeObservable } from 'mobx';
import { Location } from 'history';

import { IRootStore } from '../typings/store';
import config from '../config';
import { RouterStore } from '@ibm/mobx-react-router';

class NavigationStore {
  public static SELF = '/';

  private rootStore: IRootStore;
  private routing: RouterStore;

  constructor(rootStore: IRootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.routing = rootStore.router;
  }

  navigateTo = (url: string | URL) => {
    if (typeof url === 'string') {
      url = new URL(url, window.location.origin);
    }
    if (url.origin !== window.location.origin) {
      window.location.href = url.toString();
      return;
    }
    if (url.toString().startsWith(config.teliaBaseUrl)) {
      window.location.href = url.toString();
      return;
    }
    url = url.toString().replace(url.origin, '');
    this.rootStore.router.history.push(url);
  };

  @computed
  get location(): Location {
    return this.rootStore.router.location;
  }

  @computed
  get path() {
    return this.toPath(this.location.pathname);
  }

  public updateLangInPath(lang: string, defaultLang: string) {
    const location = this.routing.location;
    if (location && this.langNeedsUpdating(lang, defaultLang)) {
      this.routing.replace((lang !== defaultLang ? '/' + lang : '') + this.pathnameWithoutLang() + location.search + location.hash);
    }
  }

  public langFromPath() {
    return this.pathIncludesLang() ? this.path[0] : null;
  }

  private langNeedsUpdating(lang: string, defaultLang: string) {
    return (lang !== defaultLang && this.langFromPath() !== lang) || (lang === defaultLang && this.pathIncludesLang());
  }

  private pathnameWithoutLang(path: string[] = this.path) {
    return '/' + this.pathWithoutLang(path).join('/');
  }

  private pathIncludesLang = (path: string[] = this.path) => path && path.length > 0 && ['et', 'ru', 'en'].includes(path[0]);

  private pathWithoutLang(path: string[] = this.path) {
    if (this.pathIncludesLang(path)) {
      return path.slice(1);
    }
    return path;
  }

  private toPath(pathname: string) {
    return pathname.split('/').filter(path => path);
  }
}

export default NavigationStore;
