import { RootStore } from 'store/RootStore';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    INITIAL_CONFIG: any;
    dataLayer: any;
    CookieControl: any;
    Cookiebot: any;
    PLUMBR: any;
    rootStore: RootStore;
  }
}

export default { ...window.INITIAL_CONFIG };
