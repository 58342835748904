import { RouterStore, syncHistoryWithStore } from '@ibm/mobx-react-router';
import * as mobx from 'mobx';
import { merge } from 'lodash';

import LocaleStore from './LocaleStore';
import { IRootStore } from '../typings/store';
import TranslateStore from './TranslateStore';
import NavigationStore from './NavigationStore';
import SpinnerStore from '../component/spinner/SpinnerStore';
import ErrorStore from './ErrorStore';
import BrandfulStore from './BrandfulStore';
import FeatureStore from '../component/featureFlag/FeatureStore';
import { createBrowserHistory } from 'history';

let rootStoreInitialized = false;

export class RootStore implements IRootStore {
  router: RouterStore;
  localeStore: LocaleStore;
  translateStore: TranslateStore;
  featureStore: FeatureStore;
  navigationStore: NavigationStore;
  spinnerStore: SpinnerStore;
  errorStore: ErrorStore;
  brandfulStore: BrandfulStore;

  constructor() {
    this.spinnerStore = new SpinnerStore();
    this.router = new RouterStore();
    syncHistoryWithStore(createBrowserHistory(), this.router);
    this.navigationStore = new NavigationStore(this);
    this.localeStore = new LocaleStore(this);
    this.translateStore = new TranslateStore(this);
    this.featureStore = new FeatureStore(this);
    this.errorStore = new ErrorStore(this);
    this.brandfulStore = new BrandfulStore();
  }

  async init() {
    if (rootStoreInitialized) {
      return;
    }
    await Promise.all([this.translateStore.init(), this.featureStore.init()]);
    rootStoreInitialized = true;
  }

  async dataReset() {
    this.errorStore.reset();
  }

  @mobx.computed
  get state() {
    const currentState = {};

    for (const storeName of Object.keys(this)) {
      for (const prop of Object.keys(this[storeName])) {
        if (mobx.isObservableProp(this[storeName], prop)) {
          currentState[storeName] = {
            ...currentState[storeName],
            [prop]: mobx.toJS(this[storeName][prop])
          };
        }
      }
    }

    return currentState;
  }

  @mobx.action
  setState(state) {
    const [argumentType] = {}.toString.call(state).match(/\[object (.+)\]/);

    if (argumentType !== 'Object') {
      const error = new Error(
        `Failed to set application state. Expecting new state object to be an Object but ${argumentType} was passed.`
      );
      this.errorStore.setGeneralTechnicalError(error);
      return;
    }

    for (const storeName in state) {
      /* istanbul ignore else */
      if (this.hasOwnProperty(storeName)) {
        for (const prop in state[storeName]) {
          /* istanbul ignore else */
          if (this[storeName].hasOwnProperty(prop) && mobx.isObservableProp(this[storeName], prop)) {
            const getMerged = () => merge(mobx.toJS(this[storeName][prop]), state[storeName][prop]);
            let value;

            switch (true) {
              case mobx.isObservableMap(this[storeName][prop]):
                value = new Map(Object.entries(getMerged()));
                break;
              case mobx.isObservableArray(this[storeName][prop]):
              case mobx.isObservableObject(this[storeName][prop]):
                value = getMerged();
                break;
              default:
                value = state[storeName][prop];
            }

            mobx.set(this[storeName], prop, value);
          }
        }
      }
    }
  }
}

export default (): RootStore => {
  if (process.env.NODE_ENV === 'development') {
    window.rootStore = window.rootStore || new RootStore();
    return window.rootStore;
  }

  return new RootStore();
};
