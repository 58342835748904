import { AxiosResponse } from 'axios';
import API from '../util/ApiUtil';
import FeatureFlag from '../model/featureFlag/FeatureFlag';

const FEATURE_FLAGS = '/feature-flags/ui';

export class RoamingRedirectApi {
  static fetchFeatureFlags = (): Promise<FeatureFlag[]> => API.get(`${FEATURE_FLAGS}`, {}).then((response: AxiosResponse) => response.data);
}

export default RoamingRedirectApi;
