import '@babel/polyfill';
import 'url-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { enableLogging } from 'mobx-logger';

import './util/setupComponentValues'; // must be imported before App
import App from './App';
import * as mobx from 'mobx';

mobx.configure({
  enforceActions: 'observed',
  useProxies: 'never'
});

if (process.env.NODE_ENV === 'development') {
  enableLogging({
    action: true,
    reaction: true,
    transaction: true,
    compute: true
  });
}

ReactDOM.render(<App />, document.getElementById('container'));
